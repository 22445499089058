/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import Layout from "../../layouts/default"
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Text from "../../components/text"
import Divider from "../../components/divider"

const NeuigkeitenInklusiv = () => {
  const data = useStaticQuery(graphql`
    query NeuigkeitenInklusiv {
      cover: file(
        relativePath: { eq: "neuigkeiten/cover-podcast-inklusiv.png" }
      ) {
        ...smallImage
      }
      potsch: file(relativePath: { eq: "neuigkeiten/potsch.jpg" }) {
        ...smallImage
      }
      jaeckel: file(relativePath: { eq: "neuigkeiten/jaeckel.jpg" }) {
        ...smallImage
      }
      bodecker: file(relativePath: { eq: "neuigkeiten/bodecker.jpg" }) {
        ...smallImage
      }
      helmle: file(relativePath: { eq: "neuigkeiten/helmle.jpg" }) {
        ...smallImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlin inklusiv? Hölderlin inklusiv!"
        description="Ein Podcast zur aktuellen Sonderausstellung"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Hölderlin inklusiv? Hölderlin inklusiv!",
              link: "/neuigkeiten/hoelderlin-inklusiv",
            },
          ]}
        />
        <PageTitle>Hölderlin inklusiv? Hölderlin inklusiv!</PageTitle>
        <Columns space={12} collapse={[true, true, false]}>
          <Column width={64}>
            <Image
              alt="Titelbild zum Podcast Hölderlin inklusiv? Hölderlin inklusiv!"
              image={data.cover.childImageSharp.gatsbyImageData}
            />
          </Column>
          <Column>
            <Stack>
              <Heading level={5}>
                Ein Podcast zur aktuellen Sonderausstellung
              </Heading>
              <AudioPlayer
                src="neuigkeiten/podcast-inklusiv"
                title="Hölderlin inklusiv? Hölderlin inklusiv!"
              />
              <Paragraph dropcap={true}>
                Im Vorfeld unserer aktuellen Sonderausstellung zu Hölderlin in
                Einfacher Sprache und Gebärdensprache haben Rhetorik-Studierende
                der Uni Tübingen unter Leitung von Bernadette Schoog einen
                Podcast produziert. Sie haben die Entstehung der Ausstellung ein
                Stück begleitet und Interviews mit vier an der Ausstellung
                beteiligten Personen geführt:
              </Paragraph>
              <Stack space={6}>
                <Columns space={[6, 6, 8]} alignY="center">
                  <Column width={[24, 32]}>
                    <Image
                      image={data.potsch.childImageSharp.gatsbyImageData}
                      alt="Portrait Sandra Potsch"
                      sx={{
                        borderRadius: "9999em",
                      }}
                    />
                  </Column>
                  <Column>
                    <Paragraph size={[3, 3, 4]}>
                      Mit der Museumsleiterin Sandra Potsch
                    </Paragraph>
                  </Column>
                </Columns>
                <Columns space={[6, 6, 8]} alignY="center">
                  <Column width={[24, 32]}>
                    <Image
                      image={data.helmle.childImageSharp.gatsbyImageData}
                      alt="Portrait Sandra Potsch"
                      sx={{
                        borderRadius: "9999em",
                      }}
                    />
                  </Column>
                  <Column>
                    <Paragraph size={[3, 3, 4]}>
                      Mit Krishna-Sara Helmle vom ›Textöffner – Büro für Leichte
                      Sprache in Tübingen‹
                    </Paragraph>
                  </Column>
                </Columns>
                <Columns space={[6, 6, 8]} alignY="center">
                  <Column width={[24, 32]}>
                    <Image
                      image={data.jaeckel.childImageSharp.gatsbyImageData}
                      alt="Portrait Sandra Potsch"
                      sx={{
                        borderRadius: "9999em",
                      }}
                    />
                  </Column>
                  <Column>
                    <Paragraph size={[3, 3, 4]}>
                      Mit der Autorin Stephanie Jaeckel, die Texte in Einfacher
                      Sprache für die Ausstellung und das dazugehörige
                      Begleitbuch verfasst hat
                    </Paragraph>
                  </Column>
                </Columns>
                <Columns space={[6, 6, 8]} alignY="center">
                  <Column width={[24, 32]}>
                    <Image
                      image={data.bodecker.childImageSharp.gatsbyImageData}
                      alt="Portrait Sandra Potsch"
                      sx={{
                        borderRadius: "9999em",
                      }}
                    />
                  </Column>
                  <Column>
                    <Paragraph size={[3, 3, 4]}>
                      Und mit der Illustratorin Jette von Bodecker, die die
                      passenden Bilder dazu entwickelt
                    </Paragraph>
                  </Column>
                </Columns>
              </Stack>
              <Paragraph>
                Sie schildern ihre Beweggründe für die Mitarbeit an der
                Ausstellung, geben Einblick in ihre Arbeitsprozesse und
                berichten von dem, was sie antreibt und herausfordert.
              </Paragraph>
              <Divider size={4} />
              <Text>
                Ein Projekt der Studierenden des Seminars für Allgemeine
                Rhetorik der Eberhard-Karls-Universität Tübingen unter der
                Leitung von Bernadette Schoog.
              </Text>
              <Text>
                Die Ausstellung und das Begleitbuch in Einfacher Sprache werden
                unterstützt durch das Ministerium für Soziales und Integration
                Baden-Württemberg
              </Text>
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenInklusiv
